import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { changeStep, updateForm } from 'actions/formActions';
import { useFormik } from 'formik';
import FormikDropdown from 'components/inputs/FormikDropDown';
import FormikTextInput from 'components/inputs/FormikInputs';
import { toast } from 'react-toastify';

const defaultValues = {
  1: {
    connector: '',
    acStart: '',
    acStop: '',
    acEnergy: '',
    socStart: '',
    socStop: '',
    maxPower: '',
    dcEnergy: '',
    dcCharger: '',
    efficiency: '',
  },
  2: {
    connector: '',
    acStart: '',
    acStop: '',
    acEnergy: '',
    socStart: '',
    socStop: '',
    maxPower: '',
    dcEnergy: '',
    dcCharger: '',
    efficiency: '',
  },
  3: {
    connector: '',
    acStart: '',
    acStop: '',
    acEnergy: '',
    socStart: '',
    socStop: '',
    maxPower: '',
    dcEnergy: '',
    dcCharger: '',
    efficiency: '',
  },
  4: {
    connector: '',
    acStart: '',
    acStop: '',
    acEnergy: '',
    socStart: '',
    socStop: '',
    maxPower: '',
    dcEnergy: '',
    dcCharger: '',
    efficiency: '',
  },
};

const ChargerEfficiency = ({ currentStep }) => {
  const dispatch = useDispatch();
  const { form } = useSelector((state) => state.forms);
  const [initialValues, setInitialValues] = useState(defaultValues);

  useEffect(() => {
    if (form !== null) {
      const { chargerEfficiency = {} } = form;
      setInitialValues({
        1: { ...defaultValues[1], ...chargerEfficiency?.['1'] },
        2: { ...defaultValues[2], ...chargerEfficiency?.['2'] },
        3: { ...defaultValues[3], ...chargerEfficiency?.['3'] },
        4: { ...defaultValues[4], ...chargerEfficiency?.['4'] },
      });
    }
  }, [JSON.stringify(form)]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (form !== null && form?.id !== undefined && form?.status !== 'approved' && form?.status !== 'pending') {
        let body = {
          chargerEfficiency: values,
        };
        body.step = (currentStep + 1).toString();
        dispatch(updateForm(form?.id, body));
      } else {
        dispatch(changeStep(0));
      }
    },
  });

  // Calculate Efficiency
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      for (let i = 1; i <= 4; i++) {
        calculateEfficiency(i.toString());
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [formik.values]);

  const calculateEfficiency = (index) => {
    const acStart = parseFloat(formik.values[index].acStart);
    const acStop = parseFloat(formik.values[index].acStop);
    const acEnergy = acStop - acStart;
    if (!isNaN(acEnergy)) {
      formik.setFieldValue(`${index}.acEnergy`, acEnergy.toFixed(2));
      const dcCharger = parseFloat(formik.values[index].dcCharger);
      if (!isNaN(dcCharger) && acEnergy !== 0) {
        const efficiency = (dcCharger / acEnergy) * 100;
        formik.setFieldValue(`${index}.efficiency`, efficiency.toFixed(2));
        if (efficiency < 92) {
          toast.error(`Efficiency for Charger ${index} is less than 92%`);
        }
      } else {
        formik.setFieldValue(`${index}.efficiency`, '');
      }
    } else {
      formik.setFieldValue(`${index}.acEnergy`, '');
      formik.setFieldValue(`${index}.efficiency`, '');
    }
  };

  const options = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '0' },
    { label: '10', value: '10' },
  ];

  return (
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        <div className="card my-4 ">
          <div className="table-responsive">
            <table className="table card-table align-middle text-center  datatable  circuit ">
              {/* Table Header */}
              <thead className="align-middle">
                <tr>
                  <th rowSpan="2" scope="colgroup">
                    Sr.No.
                  </th>
                  <th rowSpan="2" scope="colgroup">
                    Connector
                  </th>
                  <th colSpan="2" scope="colgroup" style={{ textTransform: 'none' }}>
                    AC Energy <br />
                    Meter Reading (kWh)
                  </th>
                  <th rowSpan="2" scope="colgroup">
                    AC Energy <br />
                    <span style={{ textTransform: 'none' }}>(kWh)</span>
                  </th>
                  <th colSpan="2">SoC (%)</th>
                  <th rowSpan="2">
                    Max O/p <br /> Power <span style={{ textTransform: 'none' }}>(kW)</span>
                  </th>
                  <th colSpan="2">
                    DC Energy <br />
                    <span style={{ textTransform: 'none' }}>(kWh)</span>
                  </th>
                  <th rowSpan="2">
                    Efficiency <br /> (%)
                  </th>
                </tr>
                <tr>
                  <th>Start</th>
                  <th>Stop</th>
                  <th>Start</th>
                  <th>Stop</th>
                  <th>&nbsp;CMS &nbsp;</th>
                  <th>
                    Charger <br /> HMI
                  </th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {/* Rows for each charger */}
                {[1, 2, 3, 4].map((index) => (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>
                      <FormikDropdown
                        className="text-input"
                        options={options}
                        placeholder="Select"
                        isRequired
                        name={`${index}.connector`}
                        formik={formik}
                      />
                    </td>
                    <td>
                      <FormikTextInput type="text" className="text-input" isRequired name={`${index}.acStart`} formik={formik} />
                    </td>
                    <td>
                      <FormikTextInput type="text" className="text-input" isRequired name={`${index}.acStop`} formik={formik} />
                    </td>
                    <td>
                      <FormikTextInput type="text" className="text-input" isRequired name={`${index}.acEnergy`} formik={formik} />
                    </td>
                    <td>
                      <FormikTextInput type="text" className="text-input" isRequired name={`${index}.socStart`} formik={formik} />
                    </td>
                    <td>
                      <FormikTextInput type="text" className="text-input" isRequired name={`${index}.socStop`} formik={formik} />
                    </td>
                    <td>
                      <FormikTextInput type="text" className="text-input" isRequired name={`${index}.maxPower`} formik={formik} />
                    </td>
                    <td>
                      <FormikTextInput type="text" className="text-input" isRequired name={`${index}.dcEnergy`} formik={formik} />
                    </td>
                    <td>
                      <FormikTextInput type="text" className="text-input" isRequired name={`${index}.dcCharger`} formik={formik} />
                    </td>
                    <td>
                      <FormikTextInput type="text" className="text-input" isRequired name={`${index}.efficiency`} formik={formik} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Efficiency Calculation Formula */}
            <div className="row mt-3 m-2">
              <div className="col-12">
                <div className="note_div">
                  <h3>Formula for the calculation</h3>
                  <ol style={{ listStyleType: 'none', paddingLeft: '0px' }}>
                    <li>EFFICIENCY (%) = (CHARGER HMI / AC ENERGY) X 100</li>
                    <li style={{ textTransform: 'none' }}>Optional* - Feasibility for the efficiency measurement for an AC Charger on site can vary from site to site based on the electrical infrastructure.</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Previous and Next buttons */}
        <div className="buttons">
          <button
            type="button"
            className="prev-btn"
            onClick={() => {
              dispatch(changeStep(currentStep - 1));
            }}
          >
            Previous
          </button>
          {form?.status !== 'approved' && form?.status !== 'pending' ? (
            <button type="submit" className="submit-btn">
              Next
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                dispatch(changeStep(currentStep + 1));
              }}
              className="submit-btn"
            >
              Next
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

ChargerEfficiency.propTypes = {
  currentStep: PropTypes.number,
};

export default ChargerEfficiency;
